<template>
  <AuthBox>
    <h1 class="auth-title indigo--text text--darken-4 mb-3">
      {{ $t('signUpContent.signup_title') }}
    </h1>
    <form class="pt-3">
      <v-row>
        <v-col cols="12" md="6">
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.username')" :validator="$v.form.username">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.username')" />
                <v-text-field
                  v-model="form.username"
                  v-bind="attrs"
                  outlined
                  dense
                  tabindex="1"
                  class="rounded-lg"
                  :counter="150"
                  maxlength="150"
                  :placeholder="$t('form.enter_username')"
                  @input="$v.form.username.$touch()"
                  @blur="$v.form.username.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.password')" :validator="$v.form.password">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.password')" />
                <v-text-field
                  v-model="form.password"
                  v-bind="attrs"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  tabindex="3"
                  class="rounded-lg"
                  hint="At least 8 characters"
                  counter
                  :placeholder="$t('form.enter_password')"
                  @input="$v.form.password.$touch()"
                  @blur="$v.form.password.$touch()"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-col>
        <v-col cols="12" md="6">
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.email')" :validator="$v.form.email">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.email')" />
                <v-text-field
                  v-model="form.email"
                  v-bind="attrs"
                  outlined
                  dense
                  tabindex="2"
                  class="rounded-lg"
                  :placeholder="$t('form.enter_email')"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.re-password')" :validator="$v.form.rePassword">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.re-password')" />
                <v-text-field
                  v-model="form.rePassword"
                  v-bind="attrs"
                  :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showRePassword ? 'text' : 'password'"
                  outlined
                  dense
                  tabindex="4"
                  class="rounded-lg"
                  hint="At least 8 characters"
                  counter
                  :placeholder="$t('form.re-input_password')"
                  @input="$v.form.rePassword.$touch()"
                  @blur="$v.form.rePassword.$touch()"
                  @click:append="showRePassword = !showRePassword"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-col>
      </v-row>
      <v-row class="ml-4 mr-4 dashed-border">
        <v-col cols="12" class="px-0 pb-0 pt-5">
          <BaseFormGroup :name="$t('form.company_name')" :validator="$v.form.company_name">
            <template slot-scope="{ attrs }">
              <BaseLabel :title="$t('form.company_name')" />
              <v-text-field
                v-model="form.company_name"
                v-bind="attrs"
                :placeholder="$t('form.enter_company_name')"
                outlined
                dense
                tabindex="5"
                class="rounded-lg"
                @input="$v.form.company_name.$touch()"
                @blur="$v.form.company_name.$touch()"
              ></v-text-field>
            </template>
          </BaseFormGroup>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col cols="12" md="6">
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.first_name')" :validator="$v.form.first_name">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.first_name')" />
                <v-text-field
                  v-model="form.first_name"
                  v-bind="attrs"
                  :placeholder="$t('form.enter_first_name')"
                  outlined
                  dense
                  tabindex="6"
                  :counter="30"
                  maxlength="30"
                  class="rounded-lg"
                  @input="$v.form.first_name.$touch()"
                  @blur="$v.form.first_name.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.address')" :validator="$v.form.address">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.address')" />
                <v-text-field
                  v-model="form.address"
                  v-bind="attrs"
                  :placeholder="$t('form.enter_address')"
                  outlined
                  dense
                  tabindex="8"
                  class="rounded-lg"
                  @input="$v.form.address.$touch()"
                  @blur="$v.form.address.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-col>
        <v-col cols="12" md="6">
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.last_name')" :validator="$v.form.last_name">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.last_name')" />
                <v-text-field
                  v-model="form.last_name"
                  v-bind="attrs"
                  :placeholder="$t('form.enter_last_name')"
                  outlined
                  dense
                  tabindex="7"
                  :counter="30"
                  maxlength="30"
                  class="rounded-lg"
                  @input="$v.form.last_name.$touch()"
                  @blur="$v.form.last_name.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
          <v-col cols="12" class="pt-0 pb-1">
            <BaseFormGroup :name="$t('form.phone_number')" :validator="$v.form.phone_number">
              <template slot-scope="{ attrs }">
                <BaseLabel :title="$t('form.phone_number')" />
                <v-text-field
                  v-model="form.phone_number"
                  v-bind="attrs"
                  :placeholder="$t('form.enter_phone_number')"
                  outlined
                  dense
                  tabindex="9"
                  maxlength="30"
                  class="rounded-lg"
                  @input="$v.form.phone_number.$touch()"
                  @blur="$v.form.phone_number.$touch()"
                ></v-text-field>
              </template>
            </BaseFormGroup>
          </v-col>
        </v-col>
      </v-row>
    </form>
    <div class="pl-3 mt-4" v-show="showCaptcha">
      <vue-hcaptcha @verify="onVerify" :sitekey="this.captchaKey"> </vue-hcaptcha>
    </div>
    <div class="pl-3 mt-4">
      <v-btn
        :disabled="this.validateRecaptcha ? false : true"
        class="mr-1 indigo white--text no-transform"
        x-large
        @click.stop="submit"
      >
        {{ $t('signUpContent.signup_btn') }}
      </v-btn>
    </div>
    <div class="mt-10">
      {{ $t('signUpContent.have_acc?') }}
      <router-link to="/sign-in" class="no-decoration">
        {{ $t('signUpContent.signin_btn') }}
      </router-link>
    </div>
    <AuthDialog v-model="showAuthDialog" @ok="ok" />
  </AuthBox>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import username from '@/validators/onlyWord';
import password from '@/validators/password';
import phoneNumber from '@/validators/phoneNumber';
import AuthBox from '@/components/auth/AuthBox';
import AuthDialog from '@/components/auth/AuthDialog.vue';
import { required, maxLength, minLength, email, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'AuthSignup',
  components: {
    AuthBox,
    AuthDialog,
    VueHcaptcha,
  },
  validations: function() {
    return {
      form: {
        username: {
          required,
          username,
          minLength: minLength(4),
          maxLength: maxLength(150),
        },
        email: { email, required },
        password: { required, password, minLength: minLength(8) },
        rePassword: { required, sameAsCreatedPassword: sameAs('password') },
        first_name: {
          required,
          maxLength: maxLength(30),
        },
        last_name: {
          required,
          maxLength: maxLength(30),
        },
        company_name: { required, minLength: minLength(3) },
        phone_number: { required, phoneNumber, minLength: minLength(8) },
        address: { required, minLength: minLength(8) },
      },
    };
  },
  data: () => ({
    form: {
      username: '',
      email: '',
      password: '',
      rePassword: '',
      first_name: '',
      last_name: '',
      company_name: '',
      phone_number: '',
      address: '',
    },
    showAuthDialog: false,
    showPassword: false,
    showRePassword: false,
    showCaptcha: true,
    captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
    validateRecaptcha: false,
  }),
  computed: {},
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.form.$invalid) {
        return;
      } else {
        this.$store
          .dispatch('Auth/signup', this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.showAuthDialog = true;
          })
          .catch(err => {
            if (err == 'Error: Unable to create account.') {
              this.$toast.error('Email already registered');
            } else {
              this.$toast.error(err.message);
            }
          });
      }
    },
    ok() {
      this.$router.push({ name: 'AuthLogin' });
    },
    onVerify(response) {
      this.validateRecaptcha = response;
      this.showCaptcha = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.no-transform {
  text-transform: none;
}
.no-decoration {
  text-decoration: none;
}
.dashed-border {
  border-top: rgba(22, 50, 92, 0.2) 3px dashed;
}
</style>
