var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AuthBox',[_c('h1',{staticClass:"auth-title indigo--text text--darken-4 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('signUpContent.signup_title'))+" ")]),_c('form',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.username'),"validator":_vm.$v.form.username},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.username')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","tabindex":"1","counter":150,"maxlength":"150","placeholder":_vm.$t('form.enter_username')},on:{"input":function($event){return _vm.$v.form.username.$touch()},"blur":function($event){return _vm.$v.form.username.$touch()}},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.password'),"validator":_vm.$v.form.password},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.password')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"outlined":"","dense":"","tabindex":"3","hint":"At least 8 characters","counter":"","placeholder":_vm.$t('form.enter_password')},on:{"input":function($event){return _vm.$v.form.password.$touch()},"blur":function($event){return _vm.$v.form.password.$touch()},"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.email'),"validator":_vm.$v.form.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.email')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"outlined":"","dense":"","tabindex":"2","placeholder":_vm.$t('form.enter_email')},on:{"input":function($event){return _vm.$v.form.email.$touch()},"blur":function($event){return _vm.$v.form.email.$touch()}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.re-password'),"validator":_vm.$v.form.rePassword},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.re-password')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"append-icon":_vm.showRePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showRePassword ? 'text' : 'password',"outlined":"","dense":"","tabindex":"4","hint":"At least 8 characters","counter":"","placeholder":_vm.$t('form.re-input_password')},on:{"input":function($event){return _vm.$v.form.rePassword.$touch()},"blur":function($event){return _vm.$v.form.rePassword.$touch()},"click:append":function($event){_vm.showRePassword = !_vm.showRePassword}},model:{value:(_vm.form.rePassword),callback:function ($$v) {_vm.$set(_vm.form, "rePassword", $$v)},expression:"form.rePassword"}},'v-text-field',attrs,false))]}}])})],1)],1)],1),_c('v-row',{staticClass:"ml-4 mr-4 dashed-border"},[_c('v-col',{staticClass:"px-0 pb-0 pt-5",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.company_name'),"validator":_vm.$v.form.company_name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.company_name')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.enter_company_name'),"outlined":"","dense":"","tabindex":"5"},on:{"input":function($event){return _vm.$v.form.company_name.$touch()},"blur":function($event){return _vm.$v.form.company_name.$touch()}},model:{value:(_vm.form.company_name),callback:function ($$v) {_vm.$set(_vm.form, "company_name", $$v)},expression:"form.company_name"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-row',{staticClass:"px-1"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.first_name'),"validator":_vm.$v.form.first_name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.first_name')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.enter_first_name'),"outlined":"","dense":"","tabindex":"6","counter":30,"maxlength":"30"},on:{"input":function($event){return _vm.$v.form.first_name.$touch()},"blur":function($event){return _vm.$v.form.first_name.$touch()}},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.address'),"validator":_vm.$v.form.address},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.address')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.enter_address'),"outlined":"","dense":"","tabindex":"8"},on:{"input":function($event){return _vm.$v.form.address.$touch()},"blur":function($event){return _vm.$v.form.address.$touch()}},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}},'v-text-field',attrs,false))]}}])})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.last_name'),"validator":_vm.$v.form.last_name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.last_name')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.enter_last_name'),"outlined":"","dense":"","tabindex":"7","counter":30,"maxlength":"30"},on:{"input":function($event){return _vm.$v.form.last_name.$touch()},"blur":function($event){return _vm.$v.form.last_name.$touch()}},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}},'v-text-field',attrs,false))]}}])})],1),_c('v-col',{staticClass:"pt-0 pb-1",attrs:{"cols":"12"}},[_c('BaseFormGroup',{attrs:{"name":_vm.$t('form.phone_number'),"validator":_vm.$v.form.phone_number},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
return [_c('BaseLabel',{attrs:{"title":_vm.$t('form.phone_number')}}),_c('v-text-field',_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":_vm.$t('form.enter_phone_number'),"outlined":"","dense":"","tabindex":"9","maxlength":"30"},on:{"input":function($event){return _vm.$v.form.phone_number.$touch()},"blur":function($event){return _vm.$v.form.phone_number.$touch()}},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}},'v-text-field',attrs,false))]}}])})],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCaptcha),expression:"showCaptcha"}],staticClass:"pl-3 mt-4"},[_c('vue-hcaptcha',{attrs:{"sitekey":this.captchaKey},on:{"verify":_vm.onVerify}})],1),_c('div',{staticClass:"pl-3 mt-4"},[_c('v-btn',{staticClass:"mr-1 indigo white--text no-transform",attrs:{"disabled":this.validateRecaptcha ? false : true,"x-large":""},on:{"click":function($event){$event.stopPropagation();return _vm.submit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('signUpContent.signup_btn'))+" ")])],1),_c('div',{staticClass:"mt-10"},[_vm._v(" "+_vm._s(_vm.$t('signUpContent.have_acc?'))+" "),_c('router-link',{staticClass:"no-decoration",attrs:{"to":"/sign-in"}},[_vm._v(" "+_vm._s(_vm.$t('signUpContent.signin_btn'))+" ")])],1),_c('AuthDialog',{on:{"ok":_vm.ok},model:{value:(_vm.showAuthDialog),callback:function ($$v) {_vm.showAuthDialog=$$v},expression:"showAuthDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }